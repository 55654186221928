// extracted by mini-css-extract-plugin
export var column = "UserNetworkMplsProject_Details_LocationDocuments__column__WCbKQ";
export var container = "UserNetworkMplsProject_Details_LocationDocuments__container__nWvOM";
export var descriptionContent = "UserNetworkMplsProject_Details_LocationDocuments__descriptionContent__MdhC0";
export var documentsHeading = "UserNetworkMplsProject_Details_LocationDocuments__documentsHeading__IZhoq";
export var downloadButton = "UserNetworkMplsProject_Details_LocationDocuments__downloadButton__s1VU8";
export var fileName = "UserNetworkMplsProject_Details_LocationDocuments__fileName__sFHZF";
export var fileNameAndIconWrapper = "UserNetworkMplsProject_Details_LocationDocuments__fileNameAndIconWrapper__l_O00";
export var geographicalRegion = "UserNetworkMplsProject_Details_LocationDocuments__geographicalRegion__c0hIp";
export var geographicalRegionAndIconWrapper = "UserNetworkMplsProject_Details_LocationDocuments__geographicalRegionAndIconWrapper__TBXsa";
export var geographicalRegionRow = "UserNetworkMplsProject_Details_LocationDocuments__geographicalRegionRow__TgxoN";
export var geographicalRegionTitle = "UserNetworkMplsProject_Details_LocationDocuments__geographicalRegionTitle__F2TT_";
export var icon = "UserNetworkMplsProject_Details_LocationDocuments__icon__G6U9k";
export var panelBody = "UserNetworkMplsProject_Details_LocationDocuments__panelBody__YGnLk";
export var panelContainer = "UserNetworkMplsProject_Details_LocationDocuments__panelContainer__WfedR";
export var row = "UserNetworkMplsProject_Details_LocationDocuments__row__WXuB6";