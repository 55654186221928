// extracted by mini-css-extract-plugin
export var additionalNotes = "UserNetworkMplsProject_Details_RequiredProducts__additionalNotes__T6XzL";
export var cardTitle = "UserNetworkMplsProject_Details_RequiredProducts__cardTitle__yUlvq";
export var checkboxes = "UserNetworkMplsProject_Details_RequiredProducts__checkboxes__qnwWM";
export var checkboxesContainer = "UserNetworkMplsProject_Details_RequiredProducts__checkboxesContainer__C1vkZ";
export var column = "UserNetworkMplsProject_Details_RequiredProducts__column___vXAp";
export var descriptionHeading = "UserNetworkMplsProject_Details_RequiredProducts__descriptionHeading__G6GOQ";
export var headingGroup = "UserNetworkMplsProject_Details_RequiredProducts__headingGroup__OUugF";
export var headingLicenseGroup = "UserNetworkMplsProject_Details_RequiredProducts__headingLicenseGroup__USpRb";
export var headingName = "UserNetworkMplsProject_Details_RequiredProducts__headingName__FEKHz";
export var lightBackground = "UserNetworkMplsProject_Details_RequiredProducts__lightBackground__jQBQt";
export var locationCard = "UserNetworkMplsProject_Details_RequiredProducts__locationCard__dhVYf";
export var locationText = "UserNetworkMplsProject_Details_RequiredProducts__locationText__yZS5O";
export var mapImage = "UserNetworkMplsProject_Details_RequiredProducts__mapImage__oZ82P";
export var notesContainer = "UserNetworkMplsProject_Details_RequiredProducts__notesContainer__oN_l5";
export var panel = "UserNetworkMplsProject_Details_RequiredProducts__panel__MK1XK";
export var panelBody = "UserNetworkMplsProject_Details_RequiredProducts__panelBody__JN9D1";
export var panelHeading = "UserNetworkMplsProject_Details_RequiredProducts__panelHeading__FqfIb";
export var productName = "UserNetworkMplsProject_Details_RequiredProducts__productName__SQ4yO";
export var productRow = "UserNetworkMplsProject_Details_RequiredProducts__productRow__DBu7Z";
export var productServiceLabel = "UserNetworkMplsProject_Details_RequiredProducts__productServiceLabel__NudjN";
export var productServiceValue = "UserNetworkMplsProject_Details_RequiredProducts__productServiceValue__oP4DP";
export var productsServiceWrapper = "UserNetworkMplsProject_Details_RequiredProducts__productsServiceWrapper__CVNUw";
export var propertiesContainer = "UserNetworkMplsProject_Details_RequiredProducts__propertiesContainer__NW0BV";
export var propertyLeft = "UserNetworkMplsProject_Details_RequiredProducts__propertyLeft__jJY_5";
export var propertyRight = "UserNetworkMplsProject_Details_RequiredProducts__propertyRight__sETA2";
export var requirements = "UserNetworkMplsProject_Details_RequiredProducts__requirements__mA6OT";
export var requirementsBlock = "UserNetworkMplsProject_Details_RequiredProducts__requirementsBlock__wESYQ";
export var row = "UserNetworkMplsProject_Details_RequiredProducts__row__J5cNO";
export var stayShaded = "UserNetworkMplsProject_Details_RequiredProducts__stayShaded__WKTG9";
export var switchLight = "UserNetworkMplsProject_Details_RequiredProducts__switchLight__Rabkc";
export var switchShaded = "UserNetworkMplsProject_Details_RequiredProducts__switchShaded__VZnaH";
export var tickmarkIcon = "UserNetworkMplsProject_Details_RequiredProducts__tickmarkIcon__F0qKH";
export var tileContainer = "UserNetworkMplsProject_Details_RequiredProducts__tileContainer__pK_ZL";
export var tileMap = "UserNetworkMplsProject_Details_RequiredProducts__tileMap__fRUVi";
export var withColon = "UserNetworkMplsProject_Details_RequiredProducts__withColon__KUaUn";