// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import Format from "date-fns/format";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectEstimatedDates from "../_components/ProjectEstimatedDates/ProjectEstimatedDates.res.js";
import * as ProjectDetailsProviders from "../_components/ProjectDetailsProviders/ProjectDetailsProviders.res.js";
import * as UserProjectDetailsOwner from "../_components/UserProjectDetailsOwner/UserProjectDetailsOwner.res.js";
import * as UserProjectDetailsDocuments from "../_components/UserProjectDetailsDocuments/UserProjectDetailsDocuments.res.js";
import * as UserProjectConciergesInformation from "../_components/UserProjectDetailsContactInformation/UserProjectConciergesInformation.res.js";
import * as UserProjectDetailsContactInformation from "../_components/UserProjectDetailsContactInformation/UserProjectDetailsContactInformation.res.js";
import * as UserNetworkMplsProject_DetailsScss from "./UserNetworkMplsProject_Details.scss";
import * as UserProjectDetailsBusinessContactInformation from "../_components/UserProjectDetailsContactInformation/UserProjectDetailsBusinessContactInformation.res.js";
import * as UserNetworkMplsProject_Details_LocationDocuments from "./UserNetworkMplsProject_Details_LocationDocuments.res.js";
import * as UserNetworkMplsProject_Details_RequiredProducts_ProductPanel from "./UserNetworkMplsProject_Details_RequiredProducts_ProductPanel.res.js";

var css = UserNetworkMplsProject_DetailsScss;

function UserNetworkMplsProject_Details(props) {
  var project = props.project;
  var match = project.status;
  var tmp;
  tmp = match === "Review" ? JsxRuntime.jsxs("p", {
          children: [
            "NOTE: Please be advised that this project is in review. ",
            "A member of Datacenters.com will reach out to discuss this project before ",
            "it becomes active."
          ],
          className: css.reviewStatusWarning
        }) : null;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("h2", {
                                      children: "Description",
                                      className: css.descriptionHeading
                                    }),
                                tmp,
                                JsxRuntime.jsx("p", {
                                      children: project.description,
                                      className: css.descriptionContent
                                    }),
                                JsxRuntime.jsx("p", {
                                      children: "Project Last Updated " + Format(project.updatedAt, "MMM dd, yyyy, h:mm a"),
                                      className: css.lastUpdated
                                    }),
                                JsxRuntime.jsx("h2", {
                                      children: "Required Products",
                                      className: css.descriptionHeading
                                    }),
                                Belt_Array.mapWithIndex(project.networkMplsConfigurations, (function (index, networkMplsConfiguration) {
                                        return JsxRuntime.jsx(UserNetworkMplsProject_Details_RequiredProducts_ProductPanel.make, {
                                                    networkMplsConfiguration: networkMplsConfiguration,
                                                    isExpanded: index === 0,
                                                    heading: "#" + (String(index + 1 | 0) + " - Network MPLS Location")
                                                  }, "product-panel-" + String(index));
                                      })),
                                project.networkProjectDocuments.length !== 0 ? JsxRuntime.jsx(UserNetworkMplsProject_Details_LocationDocuments.make, {
                                        documents: project.networkProjectDocuments,
                                        projectId: project.id,
                                        geographicalRegion: Belt_Option.getWithDefault(project.geographicalRegion, "")
                                      }) : null,
                                project.documents.length !== 0 ? JsxRuntime.jsx(UserProjectDetailsDocuments.make, {
                                        documents: project.documents,
                                        projectId: project.id
                                      }) : null
                              ],
                              className: css.colLeft
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(ProjectEstimatedDates.make, {
                                      estimatedDates: props.estimatedDates
                                    }),
                                JsxRuntime.jsx(UserProjectDetailsOwner.make, {
                                      owner: project.owner,
                                      isAgent: project.isAgent
                                    }),
                                project.contacts.length !== 0 ? JsxRuntime.jsx(UserProjectDetailsContactInformation.make, {
                                        contacts: project.contacts
                                      }) : null,
                                JsxRuntime.jsx(UserProjectDetailsBusinessContactInformation.make, {
                                      businessName: project.businessName,
                                      businessEmail: project.businessEmail,
                                      businessPhone: project.businessPhone,
                                      businessContactName: project.businessContactName,
                                      headquartersAddress: project.headquartersAddress
                                    }),
                                JsxRuntime.jsx(UserProjectConciergesInformation.make, {
                                      concierges: props.concierges
                                    })
                              ],
                              className: css.colRight
                            })
                      ],
                      className: css.columnWrapper
                    }),
                JsxRuntime.jsx(ProjectDetailsProviders.make, {
                      projectId: project.id,
                      userRole: props.userRole,
                      providers: project.providers
                    })
              ]
            });
}

var make = UserNetworkMplsProject_Details;

export {
  css ,
  make ,
}
/* css Not a pure module */
